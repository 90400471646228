import { useEffect, useRef, useState } from "react"
import { useNavigate, Link, useParams } from "react-router-dom";
import { Grid, EstadoCellRenderer } from "../../../../Layout/Grid/GridView"
import BottomSheetModal from "../../../../Layout/BottomSheetModal/BottomSheetModal"
import SelectCountries from "../../../../Layout/Forms/SelectCountries";
import Input from "../../../../Layout/Forms/Input";
import Request from "../../../../../api/Requests";
import { toast, ToastContainer } from 'react-toastify';
const InviteList = ({ info }) => {
    let paramsUrl = useParams();
    const gridInvitesRef = useRef()
    const [state, setState] = useState(null)
    const [isModalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formInvite, setFormInvite] = useState({
        "name": '',
        "email": '',
        "phone_country": '+351',
        "phone_number": '',
        "errors": []
    })
    useEffect(() => {
        setState(info)
    }, [info])


    const openModal = () => setModalVisible(true);
    const closeModal = () => setModalVisible(false);

    const handleChangeInputs = (e) => {
        setFormInvite({
            ...formInvite,
            [e.target.name]: e.target.value
        });
    }

    const seriaizeCountryList = () => {
        if (state.countryList && state.countryList.length > 0)
            return state.countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }

    const handlerSubmitSaveInvite = async () => {
        let errorArray = {};
        let error = false;
        if (formInvite.phone_number.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    phone_number: [
                        'Preencha o Nº Telemóvel'
                    ]
                }
            }
            error = true;
        }

        if (formInvite.name.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    name: [
                        'Insira o seu nome'
                    ]
                }
            }
            error = true;
        }
        if (formInvite.email.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    email: [
                        'Insira o seu email'
                    ]
                }
            }
            error = true;
        }

        if (error) {
            toast.error("Preencha os campos obrigatórios", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setFormInvite({
                ...formInvite,
                errors: errorArray
            })
            // setIsLoading(false);
            return false;
        }

        setIsLoading(true)
        const response = await Request('reserved-area/save-promoter-guest-invite', 'POST', { ...formInvite, id_events: paramsUrl.id_events }, true);
        if (response && response.status) {
            const updatedList = response.data.list;
            setState({
                ...state,
                list: updatedList,
            });
            setFormInvite({
                ...formInvite,
                "name": '',
                "email": '',
                "phone_country": '+351',
                "phone_number": '',
                "errors": []
            })
            // if (gridInvitesRef.current && gridInvitesRef.current.api) {
            //     gridInvitesRef.current.api.setRowData(updatedList);
            // }
            closeModal()
        } else {

        }
        setIsLoading(false)
    }

    return (!state ? null : (
        <>
            <div className="row">
                <div className="col-12">
                    <Grid
                        ref={gridInvitesRef}
                        rows={state.list}
                        height="50vh"
                        pagination={false}
                        cols={[
                            { hide: true, field: "id" },
                            { headerName: "Nome", field: "firstname" },
                            { headerName: "Nº Telemovel", field: "phone_number" },
                        ]}
                    />
                    <button className="btn btn-primary mt-2 w-100" onClick={openModal}><i className="fa-solid fa-plus me-3"></i>Adicionar Convidado</button>
                </div>
            </div>
            <BottomSheetModal isVisible={isModalVisible} onClose={closeModal} height="75vh">
                <div style={{ padding: '20px' }}>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label="Nome"
                                className="form-control form-input input-modal"
                                name="name"
                                type="text"
                                value={formInvite.name}
                                onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                onChange={(e) => handleChangeInputs(e)}
                                errors={formInvite.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label="Email"
                                className="form-control form-input input-modal"
                                name="email"
                                type="text"
                                value={formInvite.email}
                                onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                onChange={(e) => handleChangeInputs(e)}
                                errors={formInvite.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-grid  mx-auto ">
                            <div className="d-flex">
                                <div className="mt-2">
                                    <SelectCountries
                                        label="&nbsp;"
                                        name="phone_country"
                                        value={formInvite.phone_country}
                                        onChange={(e) => handleChangeInputs(e)}
                                        options={seriaizeCountryList()}
                                        errors={formInvite.errors}
                                    />
                                </div>
                                <div className="flex-grow-1 ms-1">
                                    <Input
                                        label="Nº Telemóvel"
                                        className="form-control form-input input-modal"
                                        name="phone_number"
                                        type="number"
                                        value={formInvite.phone_number}
                                        onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                        onChange={(e) => handleChangeInputs(e)}
                                        errors={formInvite.errors}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5" style={{ marginTop: '30px' }}>
                        <div className="col-8 d-grid  mx-auto text-center">
                            <button className="btn btn-primary" onClick={() => handlerSubmitSaveInvite()} >Guardar Convidado</button>
                        </div>
                    </div>
                </div>
            </BottomSheetModal>
        </>
    ))
}

export default InviteList