import { useState, useEffect } from "react"

const DetailsForm = ({ show, params }) => {

    const [state, setState] = useState(null)

    useEffect(() => {
        if (show) {
            setState(params)
        }
    }, [show])
    useEffect(() => {
        if (show) {
            setState(params)
        }
    }, [])

    return !show || !state ? null : (
        <div className="row mt-5">
            <div className="col-12">
                <div className="box-single-table noclick" style={{ borderWidth: 0 }}>
                    <div className="alert alert-success-ns" role="alert">
                        <i className="fa-solid fa-check me-3"></i>Pagamento efetuado com sucesso.
                        <hr />
                        Irás receber um email com o link de acesso ao teu QR code de acesso.
                    </div>
                    {/* {
                        state.tickets.map((item) => {
                            return (
                                <div className="row">
                                    <div className="col-12">
                                        Ticket: {item.name}
                                    </div>
                                </div>
                            )
                        })
                    } */}
                </div>
            </div>
        </div>
    )
}

export default DetailsForm;