import Request from "../../../api/Requests";

const generateTickettransaction = (params) => {
    return Request('guestlist/ticket-generate-transaction', 'POST', params);
}


const getInfoEventTransaction = (params) => {
    return Request('guestlist/ticket-event-info', 'POST', params);
}


const submitPurchaseTicket = (params) => {
    return Request('guestlist/ticket-purchase-confirm', 'POST', params);
}

export default {
    generateTickettransaction,
    getInfoEventTransaction,
    submitPurchaseTicket
}