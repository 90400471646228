const TransactionExpired = ({show}) => {
    return show ? (
        <div className="row mt-5">
            <div className="col-12">
                <div className="row">
                    <div className="col-12 alert alert-danger">
                        <i className="fa-solid fa-times me-3 fs-1"></i>O pagamento excedeu o prazo de pagamento ou foi recusado.
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default TransactionExpired;