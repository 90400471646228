import { useState, useEffect } from "react";
import BottomSheetModal from "../../../../Layout/BottomSheetModal/BottomSheetModal";
import { toast } from 'react-toastify';
import Request from "../../../../../api/Requests";
import { useParams } from "react-router-dom";
import { Input, SelectCountries } from "../../../../Layout/Forms/Form"

const TablesList = ({ info, refreshData }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState(null)
    const [formTable, setformTable] = useState({
        "name": '',
        "email": '',
        "phone_country": '+351',
        "phone_number": '',
        "contribuinte": '',
        "payment_phone_number": '',
        "errors": []
    })
    const [modalTableReservation, setModalTableReservation] = useState({
        isVisible: false,
        data: null
    });
    let paramsUrl = useParams();
    useEffect(() => {
        setState(info)
    }, [info])
    useEffect(() => {
        if (formTable.payment_phone_number.length == 0 && formTable.phone_number.length == 9) {
            setformTable({
                ...formTable,
                payment_phone_number: formTable.phone_number
            });
        }
    }, [formTable.phone_number])

    const closeModal = () => setModalTableReservation({
        ...modalTableReservation,
        isVisible: false,
        data: null
    });

    const handleChangeInputs = (e) => {
        setformTable({
            ...formTable,
            [e.target.name]: e.target.value
        });
    }

    const seriaizeCountryList = () => {
        if (state.countryList && state.countryList.length > 0)
            return state.countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }


    const handlerActionTable = async (id) => {
        setIsLoading(true);
        const response = await Request('reserved-area/get-promoter-table-status', 'POST', { id_events: paramsUrl.id_events, id: id }, true);
        if (response && response.status) {
            if (response.data.table.status === "RESERVED") {
                toast.error("Esta mesa encontra-se reservada", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else if (response.data.table.status === "HOLD") {
                toast.warning("Esta mesa encontra-se em processo de pagamento. Poderá demorar até 6 minutos a desbloquear a mesma ou a passar a reservado", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                setModalTableReservation({
                    ...modalTableReservation,
                    isVisible: true,
                    data: response.data
                })
            }

        }
        setIsLoading(false);
    }

    const TableList = () => {
        return state.list.map((item) => {
            return (
                <div className="col-12 col-md-6 mb-4" key={item.id} onClick={() => handlerActionTable(item.id)}>
                    <div className="block-mesa h-100" >
                        <div className="block-content" style={{ borderLeft: `5px solid ${item.color}` }}>
                            <div className="block-title">
                                <h3>Mesa: {item.name}</h3>
                            </div>
                            <div className="block-details mt-3">
                                {item.base_price}<i className="fa-solid fa-euro-sign ms-1 me-2"></i>
                                <i className="fa-solid fa-users ms-2 me-1"></i>{item.numberOfGuests}


                            </div>
                        </div>
                        <div className={`block-footer ${item.status.toLowerCase()}`}>
                            <div className="ms-3">
                                Estado: <span>{item.status_text}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const handlerSubmitReserveTable = async (id) => {
        let errorArray = {};
        let error = false;
        if (formTable.phone_number.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    phone_number: [
                        'Preencha o Nº Telemóvel'
                    ]
                }
            }
            error = true;
        }

        if (formTable.name.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    name: [
                        'Insira o seu nome'
                    ]
                }
            }
            error = true;
        }
        if (formTable.email.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    email: [
                        'Insira o seu email'
                    ]
                }
            }
            error = true;
        }

        if (error) {
            toast.error("Preencha os campos obrigatórios", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setformTable({
                ...formTable,
                errors: errorArray
            })
            // setIsLoading(false);
            return false;
        }

        setIsLoading(true)
        const response = await Request('reserved-area/reserve-table-promoter', 'POST', { ...formTable, id: id, id_events: paramsUrl.id_events }, true);
        if (response && response.status) {

            // if (gridInvitesRef.current && gridInvitesRef.current.api) {
            //     gridInvitesRef.current.api.setRowData(updatedList);
            // }
            toast.success("Pedido de pagamento criado com sucesso.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            refreshData()
            closeModal()
        } else {
            toast.error("Erro a criar transação, solicite suporte. info@nightspot.pt.", {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        setIsLoading(false)
    }

    return (!state ? null : (
        <>
            <hr />
            <div className="row mt-2">
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={() => refreshData()}><i className="fa-solid fa-refresh me-3"></i>Atualizar Lista</button>
                </div>
            </div>
            <div className="row mt-3">
                <TableList />
            </div>
            <BottomSheetModal isVisible={modalTableReservation.isVisible} onClose={closeModal} height="90vh">
                {!modalTableReservation.data ? null : (
                    <div style={{ padding: '10px 20px' }}>
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    label="Nome"
                                    className="form-control form-input input-modal"
                                    name="name"
                                    type="text"
                                    value={formTable.name}
                                    onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={formTable.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    label="Email"
                                    className="form-control form-input input-modal"
                                    name="email"
                                    type="text"
                                    value={formTable.email}
                                    onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={formTable.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-grid  mx-auto ">
                                <div className="d-flex">
                                    <div className="mt-2">
                                        <SelectCountries
                                            label="&nbsp;"
                                            name="phone_country"
                                            value={formTable.phone_country}
                                            onChange={(e) => handleChangeInputs(e)}
                                            options={seriaizeCountryList()}
                                            errors={formTable.errors}
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-1">
                                        <Input
                                            label="Nº Telemóvel"
                                            className="form-control form-input input-modal"
                                            name="phone_number"
                                            type="number"
                                            value={formTable.phone_number}
                                            onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                            onChange={(e) => handleChangeInputs(e)}
                                            errors={formTable.errors}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <div className="card cards-bg-form" style={{ backgroundColor: '#00000063' }}>
                                    <h5 className="card-header"><i className="fa-solid fa-money-check-dollar me-3"></i>Pagamento</h5>
                                    <div className="card-body">

                                        Método pagamento
                                        <div className="list-group list-group-radio d-grid gap-2 border-0">
                                            <div className="position-relative">
                                                <input style={{ zIndex: 999, top: '35%' }} className="form-check-input position-absolute  end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="paumento_method_mbway" value="" onChange={() => false} checked={'checked'} />
                                                <label className="list-group-item py-3 pe-5" htmlFor="paumento_method_mbway" style={{ backgroundColor: '#0a1116', color: '#dee2e6' }}>
                                                    <strong className="fw-semibold">
                                                        <img src="/img/payments/mbway.png" className="img-fluid" style={{ height: '32px', width: 'auto' }} />
                                                    </strong>
                                                    <span className="mt-1 d-block small opacity-75">Este método de pagamento têm um valor máximo de <u>750€</u> por transferência imposto pela SIBS.</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className='row mt-2'>
                                            {/* parseFloat(modalTableReservation.data.table.percentage_pre_payment) */}
                                            {/* <div className="col-12">
                                                Valor da Mesa
                                                <div className='p-3 text-end' style={{ backgroundColor: '#0d1012', fontSize: '1.3rem', borderRadius: '8px' }}>
                                                    {(parseFloat(modalTableReservation.data.table.base_price)).toFixed(2)}€
                                                </div>
                                            </div> */}
                                            <div className='col-5'>
                                                Valor Mesa
                                                <div className='p-3 text-end' style={{ backgroundColor: '#0d1012', fontSize: '1.3rem', borderRadius: '8px' }}>
                                                    {(parseFloat(modalTableReservation.data.table.base_price)).toFixed(2)}€
                                                </div>
                                            </div>
                                            <div className='col-7'>
                                                Valor Total <span style={{ fontWeight: 200, fontSize: '0.5rem' }}>(Incluí taxa de pagamento)</span>
                                                <div className='p-3 text-end' style={{ backgroundColor: '#0d1012', fontSize: '1.3rem', borderRadius: '8px' }}>
                                                    {((parseFloat(modalTableReservation.data.table.base_price) * (parseFloat(modalTableReservation.data.table.percentage_pre_payment) / 100)) * (1 + (modalTableReservation.data.table.transaction_fee / 100))).toFixed(2)}€
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                Valor a ser pago no local
                                                <div className='p-3 text-end' style={{ backgroundColor: '#0d1012', fontSize: '1.3rem', borderRadius: '8px' }}>
                                                    {((parseFloat(modalTableReservation.data.table.base_price) - (parseFloat(modalTableReservation.data.table.base_price) * (parseFloat(modalTableReservation.data.table.percentage_pre_payment) / 100)))).toFixed(2)}€
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Input
                                                    label="Nº Telemovel MBWAY"
                                                    className="form-control form-input input-modal"
                                                    name="payment_phone_number"
                                                    type="number"
                                                    value={formTable.payment_phone_number}
                                                    onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                    onChange={(e) => handleChangeInputs(e)}
                                                    errors={formTable.errors}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Input
                                                    label="Contribuinte"
                                                    className="form-control form-input input-modal"
                                                    name="payment_phone_number"
                                                    type="number"
                                                    value={formTable.contribuinte}
                                                    onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                    onChange={(e) => handleChangeInputs(e)}
                                                    errors={formTable.errors}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-1" >
                            <div className="col-12 d-grid  mx-auto text-center">
                                <button className="btn btn-primary w-100" onClick={() => handlerSubmitReserveTable(modalTableReservation.data.table.id)} >Reservar mesa</button>
                            </div>
                        </div>
                    </div>
                )}
            </BottomSheetModal>
        </>
    ))
}


export default TablesList;