import React, { useRef } from 'react';
import { Grid } from '../../../../Layout/Grid/GridView';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Registrando os componentes do Chart.js que iremos usar
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const PromoterStats = ({ stats }) => {
    const gridPromoterStatRef = useRef()
    if (!stats.registers) {
        return null
    }

    // Dados JSON fornecidos
    const dataFromAPI = {
        stats: stats
    };

    const isVenueAdmin = () => {
        const isVenueAdmin = localStorage.getItem('nightspot_webapp_user_is_venue_admin') || 0;
        return isVenueAdmin == 'true' ? true : false;
    }
    // Mapeando os dados do JSON para os arrays que o Chart.js vai usar
    const labels = dataFromAPI.stats.registers.map(register => register.date);
    const data = dataFromAPI.stats.registers.map(register => parseInt(register.numberRegisters, 10));

    const chartData = {
        labels: labels, // Datas
        datasets: [
            {
                label: 'Número Inscrições',
                data: data, // Quantidade de registros
                borderColor: 'rgba(75, 192, 192, 1)', // Cor da linha
                backgroundColor: 'rgba(75, 192, 192, 0.2)', // Cor de fundo abaixo da linha
                fill: true, // Preencher a área abaixo da linha
                tension: 0.2, // Deixar a linha suave
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Inscrições GuestList'
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Data'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Número Inscrições'
                },
                beginAtZero: true,
                ticks: {
                    // Aqui garantimos que os valores mostrados no eixo Y sejam números inteiros
                    callback: function (value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    },
                    stepSize: 1, // Define o intervalo entre os números no eixo Y
                },
            }
        }
    };

    return (
        <>
            <h4 className="mt-4">Guest List</h4>
            <div className="row mt-3">
                <div className="col-4 col-sm-4">
                    <div className="p-3" style={{ background: 'linear-gradient(to right, rgb(10, 194, 130), rgb(13, 243, 163))', borderRadius: '8px' }}>
                        <div className="row">
                            <div className="col-8 ">
                                <div className="row">
                                    <div className="col-12 fs-3">{dataFromAPI.stats.headStats.total}</div>
                                </div>
                            </div>
                            <div className="col-4">
                                <i className="fa-solid fa-chart-bar" aria-hidden="true" style={{ fontSize: '1.5rem', lineHeight: '1.5rem', color: 'rgba(255, 255, 255, 0.75)' }}></i>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 fs-6">Nº Registos</div>
                        </div>
                    </div>
                </div>
                <div className="col-4 col-sm-4">
                    <div className="p-3" style={{ background: 'linear-gradient(to right, rgb(1, 169, 172), rgb(1, 219, 223))', borderRadius: '8px' }}>
                        <div className="row">
                            <div className="col-8 ">
                                <div className="row">
                                    <div className="col-12 fs-3">{dataFromAPI.stats.headStats.totalCheckIn}</div>
                                </div>
                            </div>
                            <div className="col-4">
                                <i className="fa-solid fa-user-check" aria-hidden="true" style={{ fontSize: '1.5rem', lineHeight: '1.5rem', color: 'rgba(255, 255, 255, 0.75)' }}></i>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 fs-6">Nº Entradas</div>
                        </div>
                    </div>
                </div>
                <div className="col-4 col-sm-4 mt-sm-0">
                    <div className="p-3" style={{ background: 'linear-gradient(to right, rgb(155 1 172), rgb(223 1 196))', borderRadius: '8px' }}>
                        <div className="row">
                            <div className="col-8 ">
                                <div className="row">
                                    <div className="col-12 fs-3">{dataFromAPI.stats.headStats.tx}</div>
                                </div>
                            </div>
                            <div className="col-4">
                                <i className="fa-solid fa-percent" aria-hidden="true" style={{ fontSize: '1.5rem', lineHeight: '1.5rem', color: 'rgba(255, 255, 255, 0.75)' }}></i>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 fs-6">Conversão</div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ minWidth: '250px', minHeight: '250px', width: '100%', height: '100%' }}>
                <Line data={chartData} options={options} />
            </div>

            <hr />
            <h4>Reserva de Mesas</h4>
            <div className="row mt-3">
                <div className="col-6">
                    <div className="p-3" style={{ background: 'linear-gradient(to right, rgb(229 30 99), rgb(245 0 85))', borderRadius: '8px' }}>
                        <div className="row">
                            <div className="col-8 ">
                                <div className="row">
                                    <div className="col-12 fs-3">{dataFromAPI.stats.tableStats.numReservedTables}</div>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <i className="fa-solid fa-check" aria-hidden="true" style={{ fontSize: '1.5rem', lineHeight: '1.5rem', color: 'rgba(255, 255, 255, 0.75)' }}></i>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 fs-6">Nº Reservas</div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="p-3" style={{ background: 'linear-gradient(to right, rgb(23 139 129), rgb(4 215 197))', borderRadius: '8px' }}>
                        <div className="row">
                            <div className="col-8 ">
                                <div className="row">
                                    <div className="col-12 fs-3">{dataFromAPI.stats.tableStats.balanceTables}€</div>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <i className="fa-solid fa-eur" aria-hidden="true" style={{ fontSize: '1.5rem', lineHeight: '1.5rem', color: 'rgba(255, 255, 255, 0.75)' }}></i>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 fs-6">Balanço</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: isVenueAdmin() ? 'block' : 'none'}}>
                <hr />
                <h4>Promotores</h4>
                <div className="row mt-3">
                    <div className="col-12">
                        <Grid
                            ref={gridPromoterStatRef}
                            rows={dataFromAPI.stats.promoterStats}
                            height="50vh"
                            pagination={false}
                            cols={[

                                {
                                    headerName: "Nome", field: "name",
                                    cellRenderer: (params) => {
                                        console.log(params)
                                        return (
                                            <span className="imgSpanLogo">
                                                {params.data.image && (
                                                    <img
                                                        alt={`${params.data.image}`}
                                                        src={params.data.image}
                                                        className="img-fluid"
                                                        style={{
                                                            width: '26px',
                                                            height: '26px',
                                                            borderRadius: '100px'
                                                        }}
                                                    />
                                                )}
                                                &nbsp;{params.data.name}
                                            </span>)
                                    }
                                },
                                { headerName: "Guest List", width: 30, cellStyle: { textAlign: 'center' }, field: "guestlistStats" },
                                { headerName: "Mesas", width: 100, cellStyle: { textAlign: 'center' }, field: "tabletStats" },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};



export default PromoterStats;