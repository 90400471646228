import React, { useEffect, useState, useRef } from "react";
import { SelectCountries, Input } from '../../Layout/Forms/Form';
import { useNavigate, Link, useParams } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import TicketsRequests from "./TicketsRequests";
import { toast, ToastContainer } from 'react-toastify';
import CountdownTimer from './_CountdownTimer'

const CheckOutForm = ({
    show,
    params,
    setIsLoading,
    isLoading,
    handlerEndTime,
    handlerFinishWithSucessUpdateMethodPayment
}) => {

    const [state, setState] = useState(null)
    const [isVerified, setIsVerified] = useState(false);
    const refCaptcha = useRef(null);
    const urlParams = useParams();
    useEffect(() => {
        if (show) {
            setState(params)
        }
    }, [show])
    useEffect(() => {
        if (show) {
            setState(params)
        }
    }, [])


    const seriaizeCountryList = () => {
        if (state.countryList && state.countryList.length > 0)
            return state.countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }

    const handleChangeInputs = (e) => {

        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }
    const handleRecaptchaChange = (token) => {
        setIsVerified(true);
        handleChangeInputs({
            target: {
                name: "recaptcha",
                value: token
            }
        })
    };

    const handlerSubmitTicketPurchase = async (e) => {
        e.preventDefault();
        let errorArray = {};
        let error = false;
        if (state.form.phone_number.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    phone_number: [
                        'Preenche o Nº Telemóvel'
                    ]
                }
            }
            error = true;
        }

        if (state.form.email.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    email: [
                        'Campo obrigatório'
                    ]
                }
            }
            error = true;
        }

        if (state.form.name.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    name: [
                        'Campo obrigatório'
                    ]
                }
            }
            error = true;
        }

        if (error) {
            toast.error("Preencha os campos obrigatórios", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setState({
                ...state,
                errors: errorArray
            })
            // setIsLoading(false);
            return false;
        }


        // Chamar o reCAPTCHA para obter o token de verificação
        //const recaptchaValue = refCaptcha.current.getValue();
        const recaptchaValue = await refCaptcha.current.executeAsync();
        if (recaptchaValue) {
            submitForm(recaptchaValue);
        } else {
            toast.error("Verifique o reCAPTCHA antes de submeter o formulário.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const submitForm = async (recaptchaValue) => {
        setIsLoading(true);
        const formWithToken = { ...state.form, recaptcha: recaptchaValue }
        let response = await TicketsRequests.submitPurchaseTicket({ ...urlParams, ...formWithToken });
        if (response && response.status == true) {
            handlerFinishWithSucessUpdateMethodPayment()
            //const urlPromoterTable = `/${type == `venue` ? `v` : `u`}/${urlParams.slug}/${state.event.slug}/tables/${state.table.id}`
            //navigate(`${urlPromoterTable}/transaction/${response.data.transaction_id}`);
        } else {
            if (response.msg && response.msg.length > 0) {
                toast.error(response.msg, {
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }
        setIsLoading(false)
    }


    return !show || !state ? null : (
        <>
            <div className="row mt-3 position-relative z-2" >
                <div className="col-12">
                    <div className="box-single-table noclick fs-5" style={{ borderWidth: 0 }}>
                        Têm <CountdownTimer endDate={state.transaction.transaction_date_create} handlerEndTime={() => handlerEndTime()} /> para finalizar a sua compra
                    </div>
                </div>
            </div>
            <div className="row mt-3 position-relative z-2" >
                <div className="col-12">
                    <div className="box-single-table noclick" style={{ borderWidth: 0 }}>
                        <div className="row">
                            <div className="col-12">
                                <h3>Check-out</h3>
                            </div>
                        </div>
                        <form onSubmit={handlerSubmitTicketPurchase} >
                            <div className="row">
                                <div className=" col-12 col-sm-7 ">
                                    <label className="form-label">Dados da compra</label>
                                    <div className="row">
                                        <div className="col-12 ">
                                            <Input
                                                label="Nome"
                                                name="name"
                                                type="text"
                                                value={state.form.name}
                                                onChange={(e) => handleChangeInputs(e)}
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 d-grid  mx-auto ">

                                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>

                                                <SelectCountries
                                                    label="&nbsp;"
                                                    name="phone_country"
                                                    value={state.form.phone_country}
                                                    onChange={(e) => handleChangeInputs(e)}
                                                    options={seriaizeCountryList()}
                                                    errors={state.errors}

                                                />
                                                <div className="ms-1 w-100">
                                                    <Input
                                                        label="Nº Telemóvel"
                                                        name="phone_number"
                                                        type="number"
                                                        value={state.form.phone_number}
                                                        onChange={(e) => handleChangeInputs(e)}
                                                        errors={state.errors}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-12 ">
                                            <Input
                                                label="Email"
                                                name="email"
                                                type="text"
                                                value={state.form.email}
                                                onChange={(e) => handleChangeInputs(e)}
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 ">
                                            <Input
                                                label="Contribuinte"
                                                name="contribuinte"
                                                type="text"
                                                value={state.form.contribuinte}
                                                onChange={(e) => handleChangeInputs(e)}
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: '30px' }}>
                                        <div className="col-12">
                                            Método pagamento
                                            <div className="list-group list-group-radio d-grid gap-2 border-0">
                                                <div className="position-relative">
                                                    <input style={{ zIndex: 999, top: '35%' }} className="form-check-input position-absolute  end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="paumento_method_mbway" value="" onChange={() => false} checked={'checked'} />
                                                    <label className="list-group-item py-3 pe-5" htmlFor="paumento_method_mbway" style={{ backgroundColor: '#0a1116', color: '#dee2e6' }}>
                                                        <strong className="fw-semibold">
                                                            <img src="/img/payments/mbway.png" className="img-fluid" style={{ height: '32px', width: 'auto' }} />
                                                        </strong>
                                                        <span className="mt-1 d-block d-none small opacity-75">Este método de pagamento têm um valor máximo de <u>750€</u> por transferência imposto pela SIBS.</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="row" style={{ marginTop: '30px' }}>
                                        <div className="col-12">
                                            <ReCAPTCHA size="invisible" ref={refCaptcha} theme="dark" sitekey="6LeJQrwpAAAAAHSzvB8Z_eA8SBZk9YBOBwBU6qyz" onChange={handleRecaptchaChange} />

                                        </div>
                                    </div>


                                </div>
                                <div className="col-12 col-sm-5 ">
                                    <h3>Resumo</h3>
                                    <table className="table table-dark table-striped">
                                        <thead>
                                            <tr>
                                                <td>Descrição</td>
                                                <td>Valor</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr style={{ fontSize: '10px' }}>
                                                <td>Bilhetes</td>
                                                <td>{state.transaction.transaction_price_ticket}€</td>
                                            </tr>
                                            <tr style={{ fontSize: '10px' }}>
                                                <td>Custo operação</td>
                                                <td>{state.transaction.transaction_fee}€</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>Total</td>
                                                <td>{state.transaction.transaction_price_total}€</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <button type="submit" className="btn btn-primary d-block w-100" >Confirmar compra<i className="fa-solid fa-arrow-right ms-2"></i></button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    )
}

export default CheckOutForm;