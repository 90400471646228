import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import TablesRequests from "./TablesRequests";
import Loading from "../../../components/Loading";
import './TablesList.css';
import Timer from "./Timer";
import Countdown from "./Countdown";

const TableEventTransaction = ({ type }) => {

    const [isLoading, setIsLoading] = useState(true);
    const urlParams = useParams();
    const navigate = useNavigate();
    const refCaptcha = useRef(null);
    const [state, setState] = useState({
        transaction: {
            payment_expire_duration: 0,
            payment_status: 'PENDING',
        },
    })

    const getTransactionInfo = async () => {
        //setIsLoading(true)
        let response = await TablesRequests.getInfoEventTransaction(urlParams, type);
        if (response && response.status == true) {
            setState(response.data)
        } else {
            //navigate(`/`);
        }
        setIsLoading(false)
    }

    useEffect(() => {

        // const intervalId = null
        setIsLoading(true)
        const intervalId = setInterval(() => {
            getTransactionInfo();
            console.log(state.transaction.payment_status)
            if (state.transaction.payment_status !== "PENDING") {
                clearInterval(intervalId)
            }
        }, 5000);


        getTransactionInfo();


        return () => clearInterval(intervalId);
    }, [])


    const TimmerPaymmentPending = () => {
        console.log(state.transaction.payment_expire_duration)

        return (
            <div className="row">
                <div className="col-12 col-sm-6">
                    <img src="/img/payments/mbway.png" className="img-fluid" style={{ height: '80px', width: 'auto' }} />
                    <h3 className="mt-3">Pagamento Pendente</h3>
                    <div className="mt-3 fs-6">
                        Por favor verifica app mbway no teu smartphone
                    </div>
                </div>
                <div className="col-12 col-sm-6 mt-5 mt-sm-6 d-flex" >
                    <div style={{ display: 'flex', alignSelf: 'center', width: '100%', textAlign: 'center' }}>
                        {/* <Timer timeLeft={state.transaction.payment_expire_duration} /> */}
                        <Countdown initialSeconds={state.transaction.payment_expire_duration} />

                    </div>

                </div>
            </div>
        )
    }


    const PaymmentCanceled = () => {
        console.log(urlParams)
        let typeSlug = ""
        if (type == "venue") {
            typeSlug = "v"
        } else {
            typeSlug = "u"
        }
        const urlBack = "/" + typeSlug + "/" + urlParams.slug + "/" + urlParams.event + "/tables/" + urlParams.id;
        return (
            <>
                <div className="row my-3">
                    <div className="col-12 ">
                        <Link to={urlBack} className="btn btn-primary"><i className="fa-solid fa-chevron-left me-3"></i>Voltar atrás</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 alert alert-danger">
                        <i className="fa-solid fa-times me-3 fs-1"></i>O pagamento excedeu o prazo de pagamento ou foi recusado.
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12 opacity-75" style={{ fontSize: '0.7rem' }}>
                        <span className="fw-bold">ID Transação: </span>{state.transaction.transaction_number}
                    </div>
                </div>
            </>
        )
    }

    const PaymmentCompleted = () => {
        return (
            <div className="row">
                <div className="col-12 col-sm-3">
                    <div className="row">
                        <div className="col-12 ">
                            <img src={state.event.image} className="img-fluid img-event-single" />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-9">
                    <h1>{state.event.name}</h1>
                    <div className="row mt-3 mt-sm-0 ">
                        <div className="col-12">
                            {state.event.date_formated}
                            <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                            <span style={{ color: '#939393' }}>{state.event.hour_start} - {state.event.hour_end}</span>
                        </div>
                    </div>
                    {/* <div className="row mt-3 ">
                        <div className="col-12">
                            <div className="box-single-table noclick" style={{ borderColor: state.table.color }}>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <div className="table-title">Mesa {state.table.name}</div>
                                        <div className="table-description">
                                            {state.table.description}
                                        </div>
                                    </div>
                                    <div className="align-self-center">
                                        <div className="table-price">
                                            {state.table.base_price}€
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div> */}
                    <div className="row mt-3 ">
                        <div className="alert alert-success-ns" role="alert">
                            <i className="fa-solid fa-check me-3"></i>Reserva confirmada
                            <hr />
                            Vais receber um sms e email com o link de acesso ao teu QR code de acesso.
                        </div>
                    </div>
                    {/* <div className="row mt-3 ">
                        <div className="col-12 col-md-8">
                            <h3>Qr Code de Acesso</h3>
                            <div style={{ backgroundColor: '#fff', textAlign: 'center', borderRadius: '6px' }}><img className="" src={state.qrcode_url} /></div>
                        </div>
                    </div> */}
                </div>
            </div>

        )
    }

    const ContentBox = () => {
        if (state.transaction.payment_status == "CANCELED") {
            return <PaymmentCanceled />
        } else if (state.transaction.payment_status == "PENDING") {
            return <TimmerPaymmentPending />
        } else if (state.transaction.payment_status == "COMPLETED") {
            return <PaymmentCompleted />
        }
    }

    return (
        isLoading ? <Loading /> : (
            <div className="container">
                <div className="row mb-3 justify-content-center">
                    <div className="col-12 col-sm-6">
                        <div className="container position-relative z-1">
                            <div className="box-tables mt-5">
                                <ContentBox />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}
export default TableEventTransaction;