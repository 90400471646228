import React, { useState, useEffect } from 'react';

const Countdown = ({ initialSeconds }) => {
  const [time, setTime] = useState(initialSeconds); // Agora recebemos diretamente os segundos
    console.log(initialSeconds)
  useEffect(() => {
    setTime(initialSeconds)
  
    
  }, [initialSeconds])
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000); // Atualiza a cada segundo

    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
  }, []);

  // Formatação do tempo em mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return <div style={{fontSize: '4rem', width: '100%',}}>{formatTime(time)}</div>;
};

export default Countdown;
