import React, {useEffect} from "react";
import { Puff } from 'react-loading-icons'

const WaitingPayment = ({ show, paymentMethod, handlerRefresh  }) => {
    useEffect(() => {
        let timer;
        
        // Quando `show` é true, inicia o timer para chamar `handlerRefresh`
        if (show) {
            timer = setTimeout(() => {
                handlerRefresh();
            }, 2000);
        }
        
        // Limpa o timer quando `show` muda para false ou o componente é desmontado
        return () => {
            clearTimeout(timer);
        };
    }, [show, handlerRefresh]);
    return show ? (
        <div className="row mt-5">
            <div className="col-12 ">
                <div className="box-single-table noclick" style={{ borderWidth: 0 }}>
                    <Puff stroke="#f92056" height="5em" width="5em" /> Aguardar pagamento. verifique na sua aplicação mbway.
                </div>
            </div>
        </div>
    ) : null
}

export default WaitingPayment;