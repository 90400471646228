import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import TicketsRequests from "./TicketsRequests";
import { toast, ToastContainer } from 'react-toastify';
import CheckOutForm from './_CheckOutForm'
import TransactionExpired from './_TransactionExpired'
import WaitingPayment from './_WaitingPayment'
import DetailsForm from "./_DetailsForm";

const TicketTransaction = () => {

    const [isLoading, setIsLoading] = useState(true);
    const urlParams = useParams();
    const navigate = useNavigate();

    const [state, setState] = useState({
        transaction: {},
        form: null
    })

    const [contentBoxViewer, setContentBoxViewer] = useState(null)


    const getInfoEventTicket = async (showLoading = true) => {
        if(showLoading){
            setIsLoading(true)
        }
        let response = await TicketsRequests.getInfoEventTransaction(urlParams);
        if (response && response.status == true) {
            setState({
                ...state,
                countryList: response.data.countryList,
                event: response.data.event,
                tickets: response.data.tickets,
                transaction: response.data.transaction,
                form: {
                    name: '',
                    phone_country: '+351',
                    phone_number: '',
                    email: '',
                    contribuinte: '',
                    payment_method: 'MBWAY',
                }
            })
        } else {

        }
        setIsLoading(false)
    }

    useEffect( () => {
        getInfoEventTicket()
    }, [])

    useEffect(() => {
        if (state.transaction && state.transaction.status == "PENDING") {
            setContentBoxViewer("CheckOutForm")
        } else if (state.transaction && state.transaction.status == "PENDING PAYMENT") {
            setContentBoxViewer("WaitingPayment")
        } else if (state.transaction && state.transaction.status == "COMPLETED") {
            setContentBoxViewer("DetailsForm")
        }else if(state.transaction && state.transaction.status == "CANCELED"){
            setContentBoxViewer("TransactionExpired")
        }
    }, [state.form])



    const ContentBox = () => {
        return (
            <>
                <CheckOutForm
                    show={contentBoxViewer == "CheckOutForm" ? true : false}
                    params={state}
                    setIsLoading={(param) => setIsLoading(param)}
                    isLoading={isLoading}
                    handlerEndTime={() => setContentBoxViewer("TransactionExpired")}
                    handlerFinishWithSucessUpdateMethodPayment={() => getInfoEventTicket()}
                />
                <TransactionExpired
                    show={contentBoxViewer == "TransactionExpired" ? true : false}
                />
                <WaitingPayment
                    show={contentBoxViewer == "WaitingPayment" ? true : false}
                    handlerRefresh={() => getInfoEventTicket(false)}
                />
                <DetailsForm
                    show={contentBoxViewer == "DetailsForm" ? true : false}
                    params={state}
                />
            </>)

    }

    return (
        isLoading ? <Loading /> : (
            <div className="container-fluid">
                <div className="position-absolute opacity-25 dark:opacity-25" style={{ minHeight: '100vh', background: `url('${state.event.image}') center center / cover`, left: '-30%', top: '-30%', width: '100%', filter: 'blur(100px)' }}>
                </div>
                <div className="container position-relative z-1">
                    <div className="box-tables mt-5">
                        <div className="row">
                            <div className="col-12 col-sm-3">
                                <div className="row">
                                    <div className="col-12 ">
                                        <img src={state.event.image} className="img-fluid img-event-single" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-9">
                                <h1>{state.event.name}</h1>
                                <div className="row mt-3 mt-sm-0 ">
                                    <div className="col-12">
                                        {state.event.date_formated}
                                        <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                                        <span style={{ color: '#939393' }}>{state.event.hour_start} - {state.event.hour_end}</span>
                                    </div>
                                </div>
                                
                                <ContentBox />


                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    )
}

export default TicketTransaction;