import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ endDate, handlerEndTime }) => {
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    // Converte a data passada por parâmetro em um objeto Date
    const end = new Date(endDate.replace(' ', 'T')); // Substitui o espaço por 'T' para compatibilidade com o formato ISO

    // Função para calcular o tempo restante
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = end - now;

      if (difference > 0) {
        const hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0');
        const minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0');
        const seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, '0');

        setTimeRemaining(`${minutes}:${seconds}`);
      } else {
        setTimeRemaining('00:00');
        handlerEndTime()
      }
    };

    // Atualiza o tempo restante a cada segundo
    const timer = setInterval(calculateTimeLeft, 1000);

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(timer);
  }, [endDate]);

  return timeRemaining;
};

export default CountdownTimer;
